<template>
  <div class="video">
    <img :src="`${this.$imgUrl}img_i_9.png`" />
    <div class="video-con view_flex_cc">
      <div class="view_flex_cc view_flex_csb">
        <div
          class="btn-play animated"
          :class="{ rotateIn: animated }"
          @click="open"
        >
          <img src="@/assets/images/play.svg" />
        </div>
        <div class="size_24">{{ $t("indexText.videoText") }}</div>
      </div>
    </div>
    <v-dialog v-model="dialog" width="1080">
      <template>
        <div class="videoBox animated bounceInDown">
          <iframe
            v-if="dialog"
            frameborder="0"
            src="https://v.qq.com/txp/iframe/player.html?vid=f33101anw2w"
            allowFullScreen="true"
          ></iframe>
        </div>
      </template>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "component_name",
  props: {
    animated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.video {
  height: 500px;
  position: relative;
  .video-con {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    color: #fff;
    .btn-play {
      width: 108px;
      height: 108px;
      margin-bottom: 41px;
      cursor: pointer;
    }
  }
}

.videoBox {
  iframe {
    width: 100%;
    height: 500px;
  }
}
</style>
