<template>
  <div class="hot">
    <div class="containerView">
      <div class="hot-box">
        <div class="hot-title text_center">
          <h3 class="size_46">HOT</h3>
          <h4 class="size_22" v-html="$t('indexText.hotText')"></h4>
        </div>
        <div class="hot-con">
          <template v-for="(item, index) in list">
            <div
              :key="index"
              :id="`animate${index + 1}`"
              class="view_flex_cc animated"
              :class="{ fadeInUp: animated }"
            >
              <div class="goods-card scale" @click="router(item.productId)">
                <div class="imgBox">
                  <v-img
                    :src="
                      $lineUrl + item.mainImage + '?imageView2/2/w/600/h/600'
                    "
                    :lazy-src="
                      $lineUrl + item.mainImage + '?imageView2/2/w/265/h/265'
                    "
                  />
                </div>
                <div class="mask view_flex_cc">
                  <div class="text_center">
                    <div class="mask-name text_rowMore">
                      {{ item.productName }}
                    </div>
                    <div class="mask-qrcode">
                      <img
                        :src="
                          $lineUrl +
                          item.miniProductQrcode +
                          '?imageView2/2/w/265/h/265'
                        "
                      />
                    </div>
                    <div class="mask-tips text_rowMore">
                      {{ $t("productrText.text5") }}
                    </div>
                    <div class="mask-check text_rowMore view_flex_cc">
                      <div>{{ $t("productrText.text6") }}</div>
                      <v-icon color="#fff" dense style="margin-top: 4px">
                        mdi-chevron-right
                      </v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="hot-more view_flex_cc">
          <div class="view_flex_c pointer nextA" @click="router(0)">
            <div class="size_28 text_bold">MORE</div>
            <div class="view_flex_cc">
              <v-icon color="#000" large> mdi-chevron-right </v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "component_name",
  props: {
    animated: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    router(id = 0) {
      this.$router.push({ name: "product", params: { id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.hot {
  padding-top: 120px;
  padding-bottom: 100px;
}

.hot-title {
  margin-bottom: 70px;
}

.hot-con > div {
  width: calc(100% / 3);
  display: inline-flex;
  margin-bottom: 50px;
}

.goods-card {
  width: 360px;
  height: 300px;
  cursor: pointer;
  overflow: hidden;
  > .imgBox,
  .mask {
    width: 100%;
    height: 100%;
    transition: all 0.6s;
  }
  .mask {
    background: rgba($color: #000, $alpha: 0.6);
    transform: translateY(0px);
    .mask-qrcode {
      width: 105px;
      height: 105px;
      background: #fff;
      border-radius: 50%;
      margin: auto;
      margin-top: 16px;
      margin-bottom: 10px;
      transition: all 0.6s;
      overflow: hidden;
    }
    .mask-name {
      font-size: 18px;
      font-weight: 600;
      margin: 0 30px;
      color: #fff;
    }
    .mask-tips {
      font-size: 14px;
      margin: 0 30px;
      color: #fff;
    }
    .mask-check {
      display: flex !important;
      margin-top: 5px;
      font-size: 12px;
      color: #fff;
    }
  }
}

.goods-card:hover {
  .imgBox {
    filter: blur(3px);
  }
  .mask {
    transform: translateY(-300px);
  }
  .mask-icon {
    transform: scale(1) rotate(0deg);
  }
}

.hot-con {
  > #animate1 {
    animation-duration: 1s; //动画持续时间完成
    animation-delay: 0.1s; //动画延迟多久才开始
  }
  > #animate2 {
    animation-duration: 1s; //动画持续时间完成
    animation-delay: 0.2s; //动画延迟多久才开始
  }
  > #animate3 {
    animation-duration: 1s; //动画持续时间完成
    animation-delay: 0.3s; //动画延迟多久才开始
  }
  > #animate4 {
    animation-duration: 1.5s; //动画持续时间完成
    animation-delay: 0.4s; //动画延迟多久才开始
  }
  > #animate5 {
    animation-duration: 1.5s; //动画持续时间完成
    animation-delay: 0.5s; //动画延迟多久才开始
  }
  > #animate6 {
    animation-duration: 1.5s; //动画持续时间完成
    animation-delay: 0.65s; //动画延迟多久才开始
  }
}
</style>
