<template>
  <div>
    <swiper :bnerList="bnerList" />
    <div class="mod1">
      <div class="containerView">
        <div class="mod1-con">
          <div
            class="mod1-t1 text_bold size_28 animated"
            :class="{ fadeInUp: animated.fadeInUp_1 }"
          >
            <div class="view_flex_c">
              <div class="b-line"></div>
              <div>
                <p style="margin: 0; margin-left: 10px">HUUUNGRY</p>
              </div>
            </div>
            <p class="text_jtf" v-html="$t('indexText.abstract.text1')"></p>
          </div>
          <div
            class="mod1-fr scale animated"
            :class="{ fadeInUp: animated.fadeInUp_2 }"
            @click="router('/abstract', 1)"
          >
            <div>
              <img :src="`${this.$imgUrl}img_i_1.png`" />
            </div>
            <div class="mask view_flex_cc">
              <div class="mask-info view_flex_cc">
                <div>
                  <div>
                    HUUUNGRY&nbsp;·&nbsp;{{ $t("indexText.abstract.text3") }}
                  </div>
                  <div class="text_center icon">
                    <v-icon color="#fff"> mdi-plus </v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mod1-fl scale animated"
            :class="{ fadeInUp: animated.fadeInUp_3 }"
            @click="router('/abstract')"
          >
            <div>
              <img :src="`${this.$imgUrl}img_i_2.png`" />
            </div>
            <div class="mask view_flex_cc">
              <div class="mask-info view_flex_cc">
                <div>
                  <div>
                    HUUUNGRY&nbsp;·&nbsp;{{ $t("indexText.abstract.text3") }}
                  </div>
                  <div class="text_center icon">
                    <v-icon color="#fff"> mdi-plus </v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mod1-fc grayView scale animated"
            :class="{ fadeInUp: animated.fadeInUp_4 }"
          >
            <div>
              <img :src="`${this.$imgUrl}img_i_3.png`" />
            </div>
          </div>
          <div
            class="mod1-t2 text_bold size_28 view_flex_cc text_center animated"
            :class="{ fadeInUp: animated.fadeInUp_5 }"
          >
            <div class="view_flex_c view_flex_csb">
              <p>ABOUT HUUUNGRY</p>
              <p class="text_jtf">{{ $t("indexText.abstract.text2") }}</p>
              <div class="b-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mod2">
      <div class="containerView">
        <div
          class="mod2-title text_bold size_28 color_fff animated"
          :class="{ fadeInUp: animated.fadeInUp_6 }"
        >
          <div class="view_flex_c">
            <div class="b-line-light"></div>
            <div>
              <p style="margin: 0; margin-left: 10px">HUUUNGRY</p>
            </div>
          </div>
          <div class="view_flex_c">
            <div>
              <p style="margin-left: 65px">
                {{ $t("indexText.productr.text1") }}
              </p>
            </div>
          </div>
        </div>
        <div class="mod2-con view_flex">
          <div class="mod2-con-l">
            <img :src="`${this.$imgUrl}img_i_4.png`" />
          </div>
          <div class="mod2-con-r">
            <div>
              <div class="con-r-title size_20 text_bold">
                <div>@HUUUNGRY</div>
                <div v-html="$t('indexText.productr.text2')"></div>
              </div>
              <div class="con-r-text color_666 size_16">
                <p class="text_jtf" v-html="$t('indexText.productr.text3')"></p>
                <p class="text_jtf" v-html="$t('indexText.productr.text4')"></p>
              </div>
            </div>
            <div class="con-r-btn">
              <v-btn class="btn-more size_28" @click="router('/product', 2)">
                <div class="view_flex_c nextA">
                  <div>MORE</div>
                  <div>
                    <v-icon color="#fff" large> mdi-chevron-right </v-icon>
                  </div>
                </div>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mod3">
      <div class="containerView">
        <div
          class="mod3-title text_bold size_28 animated"
          :class="{ fadeInUp: animated.fadeInUp_7 }"
        >
          <div class="view_flex_c">
            <div class="b-line"></div>
            <div>
              <p style="margin: 0; margin-left: 10px">HUUUNGRY</p>
            </div>
          </div>
          <div class="view_flex_c">
            <div>
              <p style="margin-left: 65px">
                {{ $t("indexText.process.text1") }}
              </p>
            </div>
          </div>
        </div>
        <div class="mod3-con view_flex_c">
          <div class="mod3-con-l">
            <div class="scale">
              <div>
                <img :src="`${this.$imgUrl}img_i_5.png`" />
              </div>
            </div>
          </div>
          <div class="mod3-con-r view_flex_csb">
            <div class="con-r-T view_flex_sb">
              <div class="scale">
                <div class="con-r-item">
                  <img :src="`${this.$imgUrl}img_i_6.png`" />
                </div>
              </div>
              <div class="con-r-item con-r-itemTextBox size_16 text_bold">
                <p class="text_jtf" v-html="$t('indexText.process.text2')"></p>
                <p class="text_jtf" v-html="$t('indexText.process.text3')"></p>
                <div
                  class="view_flex_c pointer nextA"
                  @click="router('/process', 3)"
                >
                  <div class="size_28 text_bold">MORE</div>
                  <div class="view_flex_cc">
                    <v-icon color="#000" large> mdi-chevron-right </v-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="con-r-B view_flex_sb">
              <div class="scale">
                <div class="con-r-item">
                  <img :src="`${this.$imgUrl}img_i_7.png`" />
                </div>
              </div>
              <div class="scale">
                <div class="con-r-item">
                  <img :src="`${this.$imgUrl}img_i_8.png`" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <videoMod :animated="animated.rotateIn_8" />
    <hot
      v-if="hotShow"
      :list="hotProductList"
      :animated="animated.fadeInUp_9"
    />
  </div>
</template>
<script>
import swiper from "./components/swiper";
import hot from "./components/hot";
import videoMod from "./components/video";
import { getproductListbyHot } from "@/api/product";
import { getbanner } from "@/api/config";
export default {
  name: "home",
  data() {
    return {
      bnerList: [],
      hotProductList: [],
      bgimg: require("../../assets/images/bg.png"),
      animated: {
        fadeInUp_1: false,
        fadeInUp_2: false,
      },
      headerObj: {},
      hotShow: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.headerObj = this.$parent.$parent.$parent.$parent.$children[0];
    this.hotShow = true;
  },
  methods: {
    init() {
      Promise.all([getbanner(), getproductListbyHot()]).then((res) => {
        this.bnerList = res[0].rows;
        this.hotProductList = res[1].rows;
      });
    },
    router(path, index) {
      this.headerObj.router(path, index);
    },
  },
  components: {
    swiper,
    hot,
    videoMod,
  },
};
</script>
<style lang="scss" scoped>
.mod1 {
  padding: 100px 0;
  overflow: hidden;
  .mod1-con {
    height: 678px;
    position: relative;
    .mod1-fr {
      width: 655px;
      height: 415px;
      position: absolute;
      top: 0;
      right: 0;
    }
    .mod1-fl {
      width: 545px;
      height: 471px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .mod1-fc {
      width: 328px;
      height: 263px;
      position: absolute;
      bottom: 0;
      left: 545px;
    }
    .mod1-fl,
    .mod1-fr,
    .mod1-fc {
      cursor: pointer;
      overflow: hidden;
      > div {
        width: 100%;
        height: 100%;
      }
    }
    .mod1-t2 {
      width: 327px;
      height: 263px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.mod2 {
  height: 662px;
  background: #000;
  padding-top: 31px;
  position: relative;
  background: url("../../assets/images/bg.png") no-repeat center center;
  background-size: 100% 100%;
  .mod2-con {
    margin-top: 8px;
  }
  .mod2-con-l {
    width: 709px;
    height: 449px;
  }
  .mod2-con-r {
    width: 497px;
    height: 347px;
    background: #fff;
    padding: 35px 52px 0 55px;
    box-sizing: border-box;
    margin-top: 73px;
    position: relative;
    .con-r-title {
      margin-bottom: 18px;
    }
    .con-r-btn {
      width: 100%;
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }
    .btn-more {
      width: 208px;
      height: 58px;
      background: #000000;
      opacity: 1;
      border-radius: 0px;
      color: #ffff;
    }
  }
}

.mod3 {
  padding-top: 96px;
  padding-bottom: 120px;
  .mod3-con {
    margin-top: 37px;
    height: 486px;
    .mod3-con-l {
      width: 584px;
      height: 100%;
      overflow: hidden;
      background: #eee;
      margin-right: 50px;
    }
    .mod3-con-r {
      flex: 1;
      width: 0;
      height: 100%;
      margin-right: 75px;
      .con-r-T,
      .con-r-B {
        height: 225px;
      }
      .con-r-item {
        width: 225px;
        height: 225px;
        background: #eee;
      }
      .con-r-itemTextBox {
        background: transparent;
        padding-top: 10px;
        p {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.mod1-fr:hover,
.mod1-fl:hover {
  .mask {
    opacity: 1;
    .mask-info {
      transform: scale(1);
      .icon {
        transform: rotate(0deg);
      }
    }
  }
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
  transition: all 0.6s;
  > .mask-info {
    width: 50%;
    height: 40%;
    border: 1px solid #fff;
    position: relative;
    transform: scale(1.2);
    transition: all 0.6s;
    color: #fff;
    .icon {
      transition: all 0.6s;
      transform: rotate(-180deg);
      margin-top: 10px;
    }
  }
  .mask-info::before {
    content: "";
    position: absolute;
    width: 95%;
    height: 90%;
    z-index: -1;
  }
}
</style>
