<template>
  <div class="swiper">
    <v-carousel
      :cycle="true"
      height="800"
      delimiter-icon="mdi-minus"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(item, i) in bnerList" :key="i">
        <v-img
          class="pointer"
          :lazy-src="$lineUrl + item.url + '?imageView2/2/w/1920/h/800'"
          height="800"
          :src="$lineUrl + item.url"
          @click.stop="router(item.link)"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
export default {
  name: "component_name",
  props: {
    bnerList: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: [
        require("@/assets/images/b1.png"),
        require("@/assets/images/b2.png"),
        require("@/assets/images/b2.png"),
      ],
    };
  },
  methods: {
    router(id) {
      if (id) {
        id = Number(id);
        this.$router.push({ name: "product", params: { id, model: 4 } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  width: 100%;
}

.pointer {
  cursor: pointer;
}
</style>
